'use client';

import React, { useState, useEffect } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Toast from '@/components/Toast';
import toastErrors, { ToastErrorType } from '@/lib/toast-errors';
import useErrorState from '@/stores/error-state';

const Toaster: React.FC = () => {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const { errors, addError } = useErrorState();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  useEffect(() => {
    const errorParam = searchParams.get('err');
    if (errorParam) {
      const parsedErrors = errorParam.split(',');
      parsedErrors.forEach((error) => {
        const errorKey = error as ToastErrorType;
        if (toastErrors[errorKey]) {
          addError(error);
        }
      });

      const strippedURL = pathname.replace(/([?&])err=[^&]+(&|$)/, '$1').replace(/&$/, '');
      router.replace(strippedURL);
    }
  }, [searchParams, router, addError, pathname]);

  useEffect(() => {
    if (errors.length > 0) {
      localStorage.removeItem('error-state');
      setShowOverlay(true);
    } else {
      setShowOverlay(false);
    }
  }, [errors, setShowOverlay]);

  return (
    (showOverlay && errors.length > 0 && (
      <div className='fixed top-0 right-0 px-4 py-6 z-50 flex flex-col space-y-4'>
        <div className='pointer-events-auto fixed inset-0 bg-spring-neutral-15 opacity-70 transition-opacity z-10' />
        {errors.map((errorId) => {
          const errorKey = errorId as ToastErrorType;
          const toastError = toastErrors[errorKey];
          if (toastError) {
            return (
              <Toast
                key={toastError.id}
                id={toastError.id}
                title={toastError.title}
                description={toastError.description}
                icon={toastError.icon}
              />
            );
          }
          return null;
        })}
      </div>
    )) ||
    null
  );
};

export default Toaster;
