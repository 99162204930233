'use client';

import React, { ReactNode, Suspense, useEffect } from 'react';
import Toaster from '@/components/Toaster';
import useCartState from '@/stores/cart-state';
import analytics from '@/lib/analytics';
import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { LAUNCHDARKLY_ID } from '@/constants';

const ldConfig: ProviderConfig = {
  clientSideID: LAUNCHDARKLY_ID,
  context: {
    kind: 'user',
    key: '1',
    email: 'stores@amaze.co',
    city: 'Springfield',
    device: 'browser',
    'operating system': 'Store',
  },
  options: {
    bootstrap: 'localStorage',
  },
};

type Props = {
  children?: ReactNode;
};

const AppClientRoot: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    useCartState.persist.rehydrate();
  }, []);

  useEffect(() => {
    analytics.init();
  }, []);

  return (
    <>
      <Suspense>
        <Toaster />
      </Suspense>
      <LDProvider {...ldConfig}>{children ?? null}</LDProvider>
    </>
  );
};

export default AppClientRoot;
