'use client';

import React, { Fragment, useState, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import XIcon from '@/components/Icons/XIcon';
import analytics from '@/lib/analytics';

import useErrorState from '@/stores/error-state';
import styles from './index.module.css';

export interface ToastProps {
  id: string;
  title: string;
  description: React.ReactNode;
  icon: React.ReactNode;
}

const Toast: React.FC<ToastProps> = ({ id, title, description, icon }) => {
  const [show, setShow] = useState<boolean>(true);
  const { removeError } = useErrorState();

  useEffect(() => {
    analytics.track('error_viewed', { errorId: id, errorTitle: title });
  }, [id, title]);

  const closeToast = () => {
    setShow(false);
    removeError(id);
  };

  return show ? (
    <Transition
      show={show}
      as={Fragment}
      enter='transform ease-out duration-300 transition'
      enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
      enterTo='translate-y-0 opacity-100 sm:translate-x-0'
      leave='transition ease-in duration-100'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <div className={styles.toastContent} key={id}>
        <div className={styles.toastContentPadding}>
          <div className={styles.toastContentFlex}>
            <div className={styles.toastContentIconContainer}>{icon}</div>
            <div className={styles.toastContentDescription}>
              <h3>{title}</h3>
              <p className='mt-1 text-sm font-light text-gray-500'>{description}</p>
            </div>
            <button type='button' className={styles.closeButton} onClick={closeToast}>
              <span className='sr-only'>Close</span>
              <XIcon aria-hidden='true' />
            </button>
          </div>
        </div>
      </div>
    </Transition>
  ) : null;
};

export default Toast;
